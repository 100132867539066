<template>
   <div>
      <v-toolbar> Descargas </v-toolbar>
      <div class="ma-4">
         <v-card elevation="4" color="primary" class="mb-4">
            <v-card-title> Quick Support Agente </v-card-title>
            <v-card-text>
               Agente de la plataforma para instalación en sistemas operativos Windows. Permite la actualización automática y programada de
               aplicaciones, principalmente Microsip de manera directa o con un servidor local como intermediario.
            </v-card-text>
            <v-card-actions>
               <v-btn href="https://adconline.com.mx/setup/qsagente.zip" target="_blank" rel="noopener noreferrer" download="qsagente.zip">
                  <v-icon left>mdi-download</v-icon>
                  Descargar
               </v-btn>
            </v-card-actions>
         </v-card>
         <v-card elevation="4" color="secondary">
            <v-card-title> Quick Support Sync </v-card-title>
            <v-card-text>
               Sincronizador de la plataforma con el sistema Microsip. Se debe instalar una sola vez en la red de la empresa. Permite sincronizar los
               clientes registrados y descargar los reportes generados como remisiones.
            </v-card-text>
            <v-card-actions>
               <v-btn href="https://adconline.com.mx/setup/qssync.zip" target="_blank" rel="noopener noreferrer" download="qsagente.zip">
                  <v-icon left>mdi-download</v-icon>
                  Descargar
               </v-btn>
            </v-card-actions>
         </v-card>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style></style>
