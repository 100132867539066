<template>
  <Downloads/>
</template>

<script>
import Downloads from "../components/Downloads/Downloads.vue"

export default {
    components: {
        Downloads
    }
}
</script>

<style>

</style>